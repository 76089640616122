import { Global, Collection, Jobs } from '@sourceflow-uk/sourceflow-sdk';
import SeoHead from '../components/SeoHead';
import LatestAwards from '../components/LatestAwards';
import LatestNews from '../components/LatestNews';
import MainBanner from '../components/MainBanner';
import LatestJobs from '../components/LatestJobs';
import ImageWithVideoBanner from '../components/ImageWithVideoBanner';
import MultiColumnCTA from '../components/MultiColumnCTA';
import ImageWithTextBanner from '../components/ImageWithTextBanner';

export default function Home({
	global,
	all_awards,
	all_news,
	all_jobs,
	all_solutions,
	all_sectors
}) {
	return (
		<>
			<SeoHead />
			<MainBanner
				global={global}
				pathPrefix={``}
				customClass={``}
				content={
					<>
						<h1>We are the house of global<br></br> tech recruitment</h1>
						<p>Lorien is a digital, transformation and technology recruitment specialist. We work with clients of all sectors, sizes, and tech needs–from digital giants to tech-enabled businesses–to attract and retain top talent around the world. Through our network of specialists, we combine deep tech sector expertise with the scope, depth, and breadth of large-scale talent solutions, giving our clients the confidence and the agility to compete in the digital age.</p>
					</>
				}
				imageURL1={`/site-assets/images/main-banner.jpg`}
				imageALT1={`Main Banner`}
				imageURL2={`/site-assets/images/main-banner-overlay.webp`}
				imageALT2={`Overlay Main Banner`}
				jobSearch={true}
				pageAnchor={`more-info-of-us`}
			/>
			<ImageWithVideoBanner
				global={global}
				pathPrefix={`homepage.image_with_video_banner`}
				sectionId={`what-we-do`}
				customClass={`py-5`}
				content={<>
					<h2>It's What We Do</h2>
					<p>Forem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
				</>
				}
				buttonTitle={`Learn More About What We Do`}
				buttonLink={`/`}
				videoLink={`https://www.youtube.com/watch?v=rKka_IEN_4s`}
				bannerImage={``}
				imageURL={`/site-assets/images/what-we-do.webp`}
				imageALT={`It's what we do`}
				videoGalleryClass={`WhatWeDo`}
				pageAnchor={`more-info-of-us`}
			/>
			<LatestJobs
				global={global}
				pathPrefix={`homepage.latest_jobs`}
				customClass={`py-5`}
				post={all_jobs}
				content={<>
					<h2>Hot Jobs</h2>
					<p>Find the best tech and digital career opportunities.</p>
				</>
				}
				buttonTitle={`View All Jobs`}
				buttonLink={`/jobs/`}
				swiperID={`latestJobsSwiper`}
				locale={`https://www.lorienglobal.com`}
			/>
			<MultiColumnCTA
				global={global}
				pathPrefix={`homepage.multi_column_cta`}
				sectionId={`more-info-of-us`}
				customClass={`py-5`}
				left_content={
					<>
						<h2>Market's We Recruit</h2>
					</>
				}
				right_content={
					<>
						<h2>Business Solutions</h2>
					</>
				}
				left_post={all_sectors}
				right_post={all_solutions}
				leftColumnFilterUrl={`sectors/`}
			/>
			<LatestNews
				global={global}
				pathPrefix={`homepage.latest_news`}
				customClass={`py-5`}
				post={all_news}
				content={
					<>
						<h2>The Lorien Pulse: Latest News, Events, and Insights</h2>
						<p>Our tech expertise and partner-led approach allows us to deliver highly successful results for our clients so they can attract and retain unique talent for their business. Explore our latest collection of case studies, news, events, and insights</p>
					</>
				}
				buttonLink={`/insights/`}
				buttonTitle={`View All Insights`}
			/>
			<ImageWithTextBanner
				global={global}
				pathPrefix={`home_page.award.image_with_text_banner`}
				customClass={``}
				content={
						<>
								<h2>Largest IT Staffing Firms</h2>
								<div className='d-flex gap-3'>
									<p className='col-span-9 mb-0'>We have been ranked #1 largest IT staffing firm in the UK by SIA</p>
									<img 
										src="/site-assets/images/SIA_2024_ListLogos_LrgstStaffng_IT_UK.png" 
										alt="tes" 
										className='ratio ratio-21x9' 
										style={{ maxWidth: "80px", height: "auto", objectFit: "contain" }}
									/>
								</div>
						</>
				}
				imageURL={`/site-assets/images/contractors-image.jpg`}
				imageALT={`Job Seekers Featured Image`}
				// buttonLink={`https://ess.impellam.com/`}
				// buttonTitle={`Find out more`}
				newPageTab={true}
			/>
			<LatestAwards
				global={global}
				pathPrefix={`homepage.latest_awards`}
				customClass={`pt-5`}
				content={
					<>
						<h2>Awards & Industry Recognition</h2>
					</>
				}
				post={all_awards}
				buttonLink={``}
				buttonTitle={``}
				swiperID={`latestAwardsSwiper`}
			/>
		</>
	)
}

export async function getStaticProps({ params }) {
	const global = (await Global()).toJson();
	const awards = await Collection('awards_industry_recognition');
	const solutions = await Collection('solutions');
	const sectors = (await Collection('sectors'))
		.filter(function (post) {
			return (
				post.region?.en.includes("United Kingdom")
			);
		})

	const news = (await Collection('news'))
		.filter(function (post) {
			return (
				post.country?.en == "United Kingdom" || post.country?.en == "All"
			);
		})
	const all_jobs = (await Jobs())
		.filter(function (job) {
			return (
				job.categories
					// Pulls Sectors only
					.find((cat) => cat.id === "7710db30-b4cf-4029-b846-e02e2f0c9b3b" && cat.values.find((x) => x.name === "United Kingdom"))
			);
		})
		.filter(function (job) {
			return (
				(new Date(job?.expires_at)) >= (new Date())
			)
		})
		.getItems().slice(0, 8);
	const all_solutions = solutions.orderBy('order').getItems();
	const all_sectors = sectors.orderBy('order').getItems();
	const all_awards = awards.orderBy('order').getItems();
	const all_news = news.orderBy('date').getItems().slice(0, 8);
	return {
		props: {
			global,
			all_awards,
			all_news,
			all_jobs,
			all_solutions,
			all_sectors
		}
	};
}

// -- Reference -- //
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflow-head
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflowtext