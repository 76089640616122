import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import {
    Container,
    Row,
    Col,
    Card
} from 'reactstrap';
import styles from './styles.module.scss';
import Link from "next/link"
import md5 from "md5";
import imageMetaData from "../../.sourceflow/image_metadata.json";
import SocialLinks from '/components/SocialLinks';
import { useRouter } from "next/router";

export default function ImageWithTextBanner({
    global,
    pathPrefix,
    customClass = "",
    content,
    buttonTitle,
    buttonLink,
    imageURL,
    imageALT,
    newPageTab = false
}) {

    const router = useRouter();
    const isPageHome = router.pathname === ("/");

    return (
        <section className={`${styles.root} ${customClass}`}>
            <Row className="m-0 align-items-center">
                <Col md={7} className="left-column py-5">
                    {content ?
                        <div className="mb-4">
                            <SourceFlowText global={global} path={`${pathPrefix}.content`} type="html">
                                {content}
                            </SourceFlowText>
                        </div> : null}
                    {buttonLink ?
                        <div>
                            <Link href={`${buttonLink}`}>
                                {newPageTab ?
                                    <a className="primaryBtn no-arrow" target="_blank" rel="noopener noreferrer">
                                        {buttonTitle}
                                    </a> :
                                    <a className="primaryBtn no-arrow">
                                        {buttonTitle}
                                    </a>}

                            </Link>
                        </div> : null}
                </Col>
                <Col md={5} className="right-column p-4">
                    <SourceFlowImage
                        src={imageURL ? imageURL : "/site-assets/placeholders/img-placeholder.jpg"}
                        imagesMetaData={imageMetaData}
                        path={`${pathPrefix ? pathPrefix : md5(imageURL)}.image`}
                        size={isPageHome ? "2000x500" : "1000x500"}
                        alt={imageALT}
                        className="card-img"
                    />
                </Col>
            </Row>
        </section>
    )
}